import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const DeleteAccountForm = () => {
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);

  const reasons = [
    'No uso la aplicación',
    'Encontré una mejor alternativa',
    'Problemas técnicos',
    'Otro',
  ];

  const handleReasonChange = (e) => {
    const selectedReason = e.target.value;
    setReason(selectedReason);
    if (selectedReason === 'Otro') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
      setCustomReason(''); // Limpia el motivo personalizado si se selecciona otra opción
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailParams = {
      user_email: email,
      reason: reason === 'Otro' ? customReason : reason,
    };

    emailjs
      .send(
        'service_guix2rk', // Cambiar por tu Service ID de EmailJS
        'template_8dpjunk', // Cambiar por tu Template ID de EmailJS
        emailParams,
        'dO-ERtXAITwcUvbxe' // Cambiar por tu Public Key de EmailJS
      )
      .then(
        (response) => {
          alert('Correo enviado exitosamente');
          setEmail('');
          setReason('');
          setCustomReason('');
          setShowCustomInput(false);
        },
        (error) => {
          alert('Hubo un error al enviar el correo: ' + error.text);
        }
      );
  };

  return (
    <div className="detail-content">
      <h1>Eliminar Cuenta</h1>
      <div style={{ maxWidth: '500px', margin: '0 auto', padding: '20px' }}>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="email">Correo asociado a la cuenta:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', marginTop: '5px' }}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label>Motivo de eliminación:</label>
            {reasons.map((option, index) => (
              <div key={index}>
                <input
                  type="radio"
                  id={`reason-${index}`}
                  name="reason"
                  value={option}
                  checked={reason === option}
                  onChange={handleReasonChange}
                />
                <label htmlFor={`reason-${index}`} style={{ marginLeft: '5px' }}>
                  {option}
                </label>
              </div>
            ))}
            {showCustomInput && (
              <input
                type="text"
                value={customReason}
                onChange={(e) => setCustomReason(e.target.value)}
                placeholder="Escribe tu motivo"
                required
                style={{ width: '100%', padding: '8px', marginTop: '10px' }}
              />
            )}
          </div>
          <button
            type="submit"
            style={{
              backgroundColor: '#123D4D',
              color: '#fff',
              padding: '10px 20px',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '5px',
            }}
          >
            Enviar
          </button>
        </form>
      </div>
    </div>

  );
};

export default DeleteAccountForm;

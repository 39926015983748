import React from 'react';
import styles from './Content.module.css';

const Content = () => {
  return (
    <div className={styles.container}>
      <h2>Bienvenido a Yuspa</h2>
      <div className={styles.columns}>
        <div className={styles.column}>
          <h3>¿Qué es Yuspa?</h3>
          <p className={styles.columnDescription}>
          Yuspa te ayuda a registrar tus notas de gratitud de forma sencilla, promoviendo un hábito que mejora tu bienestar diario. Con un diseño accesible, puedes capturar y visualizar pensamientos positivos en cualquier momento. Además, hemos hecho público el código base en GitHub para que desarrolladores, tanto principiantes como expertos, puedan aprender, adaptar y contribuir, usando tecnologías como Jetpack Compose y Firebase. ¡Tu aporte y creatividad son bienvenidos!
          </p>
        </div>
        <div className={styles.column}>
          <h3>¿Por qué es Yuspa?</h3>
          <p className={styles.columnDescription}>
          Yuspa es un nombre inspirado en la palabra "Yuspagara", que significa "gracias" en aymara, una lengua indígena de los Andes. Este nombre refleja la esencia de nuestra aplicación: un espacio para expresar y cultivar la gratitud en la vida cotidiana. Elegimos Yuspa como un homenaje a la riqueza cultural de los pueblos originarios y como un recordatorio de que el acto de agradecer trasciende idiomas y fronteras, conectándonos con lo mejor de nuestra humanidad.
          </p>
        </div>
        <div className={styles.column}>
          <h3>Un elefante</h3>
          <p className={styles.columnDescription}>
          El elefante simboliza Yuspa porque representa gratitud, memoria y conexión emocional. Su habilidad para recordar y valorar a quienes son importantes refleja el acto de agradecer. Además, su naturaleza tranquila inspira empatía y unión. Su diseño amigable refuerza que la gratitud es universal y accesible para todos.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;

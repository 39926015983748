import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="detail-content">
      <h1>Términos de servicio</h1>
      <ul>
        <li><strong>Uso del servicio:</strong> Al utilizar nuestro servicio, usted acepta cumplir con estos términos de servicio y cualquier política adicional que se publique en nuestro sitio web o aplicación.</li>
        <li><strong>Propiedad intelectual:</strong> Todo el contenido proporcionado a través de nuestro servicio, incluyendo textos, imágenes, videos, y otros materiales, están protegidos por derechos de autor y otras leyes de propiedad intelectual. No se permite la reproducción, distribución o modificación del contenido sin autorización.</li>
        <li><strong>Responsabilidades del usuario:</strong> Los usuarios son responsables de mantener la seguridad de sus cuentas, incluyendo el uso de contraseñas seguras y la protección contra accesos no autorizados. Los usuarios también son responsables del uso adecuado del servicio y de cumplir con todas las leyes y regulaciones aplicables.</li>
        <li><strong>Limitación de responsabilidad:</strong> No nos hacemos responsables por cualquier daño directo, indirecto, incidental, especial, consecuente o punitivo que pueda surgir del uso o la imposibilidad de usar nuestro servicio, incluso si hemos sido informados de la posibilidad de tales daños.</li>
      </ul>
    </div>
  );
};

export default TermsOfService;

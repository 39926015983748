import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Importa el Navbar
import Header from './components/Header';
import Features from './components/Features';
import ContactUs from './components/ContactUs';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Help from './pages/Help';
import './App.css';
import SocialLinks from './components/SocialLinks';
import Content from './components/Content';
import DeleteAccountForm from './pages/DeleteAccountForm';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navbar siempre se muestra */}
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <Content />
              <Features />
              <SocialLinks />
            </>
          } />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/delete-account" element={<DeleteAccountForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/help" element={<Help />} />
        </Routes>
        {/* Footer siempre se muestra */}
        <footer>
          <a href="/terms-of-service">Terms of Service</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/help">Help</a>
          <a href="/delete-account">Delete Account</a>
        </footer>
      </div>
    </Router>
  );
}

export default App;

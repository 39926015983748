// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="detail-content">
      <h1>Políticas de privacidad</h1>
      <ul>
        <li><strong>Protección de datos personales:</strong> Nos comprometemos a proteger la privacidad y seguridad de los datos personales de nuestros usuarios. Utilizamos medidas técnicas y organizativas para prevenir el acceso no autorizado, la divulgación, la modificación o la destrucción de la información proporcionada por nuestros usuarios.</li>
        <li><strong>Seguridad de la información:</strong> Implementamos controles de seguridad para proteger la información contra amenazas como el acceso no autorizado, el uso indebido, la divulgación no autorizada o la destrucción de datos. Esto incluye el uso de cifrado de datos, autenticación de usuarios, y auditorías periódicas de seguridad.</li>
        <li><strong>Confidencialidad:</strong> Mantenemos la confidencialidad de la información proporcionada por nuestros usuarios y nos comprometemos a no divulgarla a terceros sin su consentimiento, a menos que sea requerido por ley o por un proceso legal.</li>
        <li><strong>Notificaciones de seguridad:</strong> En caso de que se detecte una brecha de seguridad que pueda afectar la información de nuestros usuarios, nos comprometemos a informar de manera oportuna y transparente sobre la situación y tomar las medidas necesarias para mitigar cualquier riesgo.</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import styles from './Features.module.css';
import { motion } from 'framer-motion';
import image1 from '../assets/firebase-logo.png';
import image2 from '../assets/kotlin-logo.png';
import image3 from '../assets/notification-icon.png';

// Datos de las características y las imágenes
const features = [
  { 
    title: 'Uso de Firebase y autenticación con Google', 
    description: 'La app integra Firebase para manejar la autenticación de usuarios mediante cuentas de Google, garantizando una experiencia segura y sin complicaciones al iniciar sesión.',
    imageUrl: image1 // Ruta de la imagen
  },
  { 
    title: 'Kotlin y Corrutinas', 
    description: 'Aprovechando Kotlin y las corrutinas, la app ofrece un rendimiento optimizado y una experiencia de usuario fluida al manejar operaciones asíncronas, como la obtención de datos desde Firebase.',
    imageUrl: image2 // Ruta de la imagen
  },
  { 
    title: 'Notificaciones', 
    description: 'Implementa notificaciones push para mantener a los usuarios informados sobre sus notas de gratitud y fomentar la práctica diaria, mejorando la interacción con la aplicación.',
    imageUrl: image3 // Ruta de la imagen
  },
];

const Features = () => {
  return (
    <section className={styles.features}>
      <h2>Características</h2>
      <div className={styles.featureList}>
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className={styles.featureItem}
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 200 }}
          >
            <img src={feature.imageUrl} alt={feature.title} className={styles.featureImage} />
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default Features;

// src/pages/Help.js
import React from 'react';

const Help = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Help</h1>
      <p>Here you can add your help content.</p>
    </div>
  );
};

export default Help;

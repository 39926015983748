import React from 'react';
import { motion } from 'framer-motion';
import './Header.css';
import appImage from '../assets/app-image.png';
import googlePlayLogo from '../assets/google-play-logo.png';
import qrCodeImage from '../assets/header-image.png';

function Header() {
  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8, delay: 0.3 } },
  };

  const imageVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8, delay: 0.5 } },
  };

  return (
    <motion.header
      className="header"
      initial="hidden"
      animate="visible"
      variants={headerVariants}
    >
      <div className="header-content">
        <motion.div className="text-content" variants={textVariants}>
          <h1>Yuspa</h1>
          <p>
          Anota tus momentos de gratitud y convierte la gratitud en un hábito diario con esta app, diseñada para inspirar bienestar personal.
          Además, compartimos el código en GitHub para que desarrolladores Android puedan usarlo como guía en sus proyectos. ¡Ya disponible para todos!
          </p>
          <div className="download-section">
            <img src={qrCodeImage} alt="Código QR de descarga" className="qr-code" />
            {/* <a
              href="https://play.google.com/store/apps/details?id=your.app.id"
              target="_blank"
              rel="noopener noreferrer"
              className="google-play-btn"
            >
              <img src={googlePlayLogo} alt="Descargar en Google Play" />
            </a> */}
          </div>

        </motion.div>
        <motion.div className="image-content" variants={imageVariants}>
          <img src={appImage} alt="Vista previa de la app" />
        </motion.div>
      </div>
    </motion.header>
  );
}

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import yuspaLogo from '../assets/yuspa-logo.png'; 
import styles from './Navbar.module.css';

function Navbar() {
  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarLogo}>
        <Link to="/">
          <img src={yuspaLogo} alt="Logo" className={styles.logo} />
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;

import React from 'react';
import './SocialLinks.css';

function SocialLinks() {
  return (
    <section className="social-links">
      <div className="container">
        <h2>Contacto</h2>
        <img src="/assets/images/foto_perfil.png" alt="Perfil" className="profile-pic" />
        <p>Jecri Do Santos</p>
        <div className="links">
          <a href="https://www.linkedin.com/in/jecri-do-santos-ocmin/" target="_blank" rel="noopener noreferrer" className="link linkedin">
            <img src="/assets/images/linkedin.png" alt="LinkedIn" />
            LinkedIn
          </a>
          <a href="https://medium.com/@jecridosantos" target="_blank" rel="noopener noreferrer" className="link medium">
            <img src="/assets/images/medium.png" alt="Medium" />
            Medium
          </a>
          <a href="https://github.com/jecridosantos/android-gratitude-wave" target="_blank" rel="noopener noreferrer" className="link github">
            <img src="/assets/images/github.png" alt="GitHub" />
            GitHub
          </a>
        </div>
      </div>
    </section>
  );
}

export default SocialLinks;
